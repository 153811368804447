import { Component, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppComponentBase } from '../../../../shared/common/app-component-base';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmationDialog.component.html',
    styleUrls: ['./confirmationDialog.component.css']
})

export class ConfirmationDialogComponent extends AppComponentBase {

    @Input() errorMessages: string[] = [];
    titleMessage: string = 'Warning'; 
    @Output() confirm = new EventEmitter<boolean>();
    @ViewChild('errorlistModal', { static: true }) modal: ModalDirective;

    constructor(injector: Injector) {
        super(injector);
    }
    onNoClick(): void {
        this.confirm.emit(false);
        this.modal.hide();
    }

    onYesClick(): void {
        this.confirm.emit(true);
        this.modal.hide();
    }

    show(title): void {
        this.titleMessage = title;
        this.modal.show();
    }
}
